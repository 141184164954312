import React from "react";
import { graphql } from "gatsby";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ImportContactsIcon from "@material-ui/icons/ImportContacts";
import _classes from "../styles/main.module.css";
import ContactForm from "../components/contact";
import Customers from "../components/customers";
import Features from "../components/features-2";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { scheduleDiscoveryCall } from "../utils/calendly";

const colors = [
  "#5364B2",
  "#8C9CF6",
  "#9C8AC5",
  "#728FDC",
  "#938DE1",
  "#6B7FE9",
  "#6A91CC",
  "#8F9CE0",
];

const useStyles = makeStyles((theme) => ({
  row: {
    margin: "auto",
    // paddingBottom: 50,
    color: "#fff",
    display: "grid",
    alignItems: "center",
    gridGap: 30,
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "300px 1fr",
      gridGap: 50,
    },
    [theme.breakpoints.up("md")]: {
      gridTemplateColumns: "432px 1fr",
    },
  },
  media: {
    width: "100%",
    height: "100%",
    maxWidth: "100%",
    maxHeight: 300,
    objectFit: "contain",
    objectPosition: "right",
    outline: 0,
    display: "block",
  },
  heroHeading: {
    color: "#23325F",
    fontSize: 26,
    fontWeight: 600,
    marginTop: 0,
    marginBottom: 20,
    lineHeight: 1.3,
    [theme.breakpoints.up("md")]: {
      fontSize: 36,
    },
  },
  heroHeading2: {
    color: "#68798D",
    fontSize: 14,
    fontWeight: 400,
    margin: 0,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },
  c_title1: {
    fontSize: 12,
    letterSpacing: "0.12em",
    color: "#68798D",
    marginBottom: 10,
    textTransform: "uppercase",
  },
  c_title2: {
    fontSize: 24,
    fontWeight: 600,
    color: "#293963",
  },
  c_summary: {
    fontSize: 14,
    lineHeight: 1.8,
    color: "#354454",
    marginBottom: 25,
  },
  c_link: {
    fontSize: 14,
    fontWeight: 600,
    display: "inline-flex",
    alignItems: "center",
  },
  c_item: {
    padding: "70px 0",
    [theme.breakpoints.up("sm")]: {
      display: "grid",
      gridTemplateColumns: "300px 1fr",
      justifyContent: "space-between",
      gridTemplateAreas: '"a c""b c"',
      gridTemplateRows: "min-content 1fr",
      gridGap: "20px 60px",
      "&:nth-child(even)": {
        gridTemplateColumns: "1fr 300px",
        gridTemplateAreas: '"c a""c b"',
      },
    },
    [theme.breakpoints.up("md")]: {
      gridGap: "20px 100px",
      gridTemplateColumns: "1fr 540px",
      "&:nth-child(even)": {
        gridTemplateColumns: "540px 1fr",
      },
    },
  },
  c_media: {
    height: 240,
    gridArea: "c",
    margin: "30px 0",
    maxWidth: "100%",
    objectFit: "contain",
    [theme.breakpoints.up("sm")]: {
      margin: 0,
      alignSelf: "center",
      width: "100%",
      height: 320,
    },
  },
  d_box: {
    [theme.breakpoints.up("md")]: {
      display: "grid",
      gridTemplateColumns: "300px 1fr",
      gridTemplateRows: "min-content min-content 1fr",
      gridGap: "0 80px",
      alignItems: "flex-start",
    },
    margin: "auto",
  },
  d_title1: {
    fontSize: 24,
    fontWeight: 600,
    color: "#fff",
    marginBottom: 18,
  },
  d_title2: {
    fontSize: 14,
    color: "rgba(255, 255, 255, 0.7)",
    maxWidth: 600,
  },
  d_cards: {
    gridRow: "1 / span 3",
    display: "grid",
    gridColumn: 2,
    gridTemplateColumns: "repeat(auto-fit, minmax(120px, 1fr))",
    gridGap: 14,
    margin: "30px 0",
    justifyContent: "center",
    [theme.breakpoints.up("sm")]: {
      justifyContent: "flex-start",
      gridTemplateColumns: "repeat(auto-fit, minmax(140px, 1fr))",
    },
    [theme.breakpoints.up("md")]: {
      margin: 0,
      justifyContent: "flex-end",
    },
  },
  d_card: {
    position: "relative",
    background: "#fff",
    boxShadow:
      "1px 1px 2px rgba(0, 0, 0, 0.04), 4px 4px 12px rgba(0, 0, 0, 0.04)",
    borderRadius: 4,
    width: "100%",
    height: 120,
    cursor: "pointer",
    overflow: "hidden",
    "&:hover": {
      boxShadow: "1px 1px 12px rgba(0, 0, 0, 0.1)",
      "& $d_footer": {
        height: "100%",
        backgroundColor: "#546fff",
      },
    },
  },
  d_footer: {
    padding: "14px 10px",
    backgroundColor: "#5364B2",
    borderRadius: 4,
    position: "absolute",
    bottom: 0,
    width: "100%",
    height: 70,
    transition: "height 0.2s ease-out, background-color 0.2s ease-out",
  },
  d_name: {
    color: "#fff",
    fontSize: 12,
    fontWeight: 600,
  },
  d_image_box: {
    // background: 'linear-gradient(223.65deg, #667EFF 0%, #4C5DC4 112.56%)',
    height: 48,
  },
  d_image: {
    width: "100%",
    height: "100%",
    objectFit: "contain",
    marginTop: 8,
  },
  d_btns: {
    marginTop: 30,
    display: "grid",
    gridGap: 20,
    gridTemplateColumns: "max-content max-content",
  },
  d_footer_cta: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: "0.04em",
    display: "flex",
    alignItems: "center",
    marginTop: 36,
    color: "#fff",
  },
  e_box: {
    border: "1px solid #f1f1f1",
    boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.04)",
    borderRadius: 8,
    height: "100%",
    overflow: "hidden",
    padding: 40,
    cursor: "pointer",
    backgroundColor: "transparent",
    transition: "background-color 0.3s ease-in",
    "&:hover": {
      backgroundColor: "#475597",
      // border: '1px solid #BDC8FF',
      boxShadow: "2px 2px 12px rgba(0, 0, 0, 0.04)",
      "& $e_title1": {
        color: "rgba(255, 255, 255, 0.6)",
      },
      "& $e_title2": {
        color: "#fff",
      },
      "& $e_body": {
        color: "rgba(255, 255, 255, 0.6)",
      },
      "& $e_cta": {
        color: "#fff",
      },
    },
  },
  e_box_inner: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  e_image: {
    width: "100%",
    height: 120,
    objectFit: "cover",
    background: "#505D9F",
  },
  e_title1: {
    fontSize: 12,
    marginBottom: 4,
    letterSpacing: "0.12em",
    textTransform: "uppercase",
    color: "#546fff",
  },
  e_title2: {
    color: "#354454",
    fontWeight: 600,
    fontSize: 16,
    letterSpacing: "0.02em",
    marginBottom: 22,
  },
  e_body: {
    fontSize: 14,
    lineHeight: 1.8,
    color: "#354454",
    flex: 1,
  },
  e_cta: {
    fontSize: 12,
    fontWeight: 600,
    letterSpacing: "0.04em",
    display: "flex",
    alignItems: "center",
    marginTop: 42,
    color: "#546fff",
  },
  f_box: {
    position: "relative",
    background: "#fff",
    boxShadow: "1px 1px 8px rgba(0, 0, 0, 0.1)",
    borderRadius: 8,
    padding: "40px 16px",
  },
  f_points: {
    display: "grid",
    gridGap: "16px 48px",
    justifyContent: "center",
    marginTop: 140,
    marginBottom: 50,
    gridTemplateColumns: "repeat(2, minmax(120px, max-content))",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "repeat(3, minmax(120px, max-content))",
    },
  },
  f_point: {
    display: "flex",
    alignItems: "center",
  },
  f_btns: {
    marginTop: 30,
    display: "grid",
    gridGap: 10,
    justifyContent: "center",
    gridTemplateColumns: "250px",
    [theme.breakpoints.up("sm")]: {
      gridTemplateColumns: "240px 240px",
    },
  },
  f_check: {
    color: "#3BA37D",
    fontSize: 16,
    marginRight: 10,
    [theme.breakpoints.up("md")]: {
      fontSize: 16,
    },
  },
  f_name: {
    color: "#8397AC",
    fontSize: 12,
    [theme.breakpoints.up("md")]: {
      fontSize: 14,
    },
  },
  g_heading: {
    color: "rgb(41, 57, 99)",
    fontSize: 24,
    fontWeight: 600,
    textAlign: "center",
    marginBottom: 48,
  },
  client_logo: {
    maxHeight: 40,
    maxWidth: "100%",
    display: "block",
  },
  bg1: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "linear-gradient(118.95deg, #EFF2FF 34.15%, #F5F7FF 83.72%)",
    },
  },
  bg2: {
    position: "relative",
    "&:before": {
      content: '""',
      position: "absolute",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: "linear-gradient(143.49deg, #F4F6FF 21.27%, #EFF2FF 65.37%)",
    },
  },
}));

const OCR = ({ data }) => {
  const classes = useStyles();

  const {
    seoTitle,
    seoDescription,
    section1,
    features,
    section3,
    getStarted,
    caseStudies,
    featurePoints,
  } = data.markdownRemark.frontmatter;

  return (
    <Layout>
      <SEO title={seoTitle} description={seoDescription} />

      <section style={{ paddingTop: 80, paddingBottom: 80 }}>
        <Container>
          <div className={classes.row}>
            <div>
              <div>
                <p className={classes.heroHeading}>{section1.heading}</p>
                <p className={classes.heroHeading2}>{section1.heading2}</p>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: 30,
                    marginLeft: -8,
                    marginRight: -8,
                  }}
                >
                  <button
                    className={clsx(_classes.button, _classes.button_outlined)}
                    style={{
                      flex: "1 0 200px",
                      margin: 8,
                    }}
                    onClick={() => {
                      window.open(section1.cta1.link, "_blank");
                    }}
                  >
                    {section1.cta1.text}
                  </button>
                  <button
                    className={_classes.button}
                    style={{
                      flex: "1 0 200px",
                      margin: 8,
                    }}
                    onClick={scheduleDiscoveryCall}
                  >
                    Request a demo
                  </button>
                </div>
              </div>
            </div>

            <div>
              <img className={classes.media} src={section1.image} alt="" />
            </div>
          </div>
        </Container>
      </section>

      <section className={classes.bg1} style={{ paddingTop: 96 }}>
        <Container style={{ position: "relative" }}>
          <div style={{ margin: "auto" }}>
            <div
              style={{
                position: "relative",
                paddingTop: "56.3%",
                background:
                  "linear-gradient(103.64deg, #48559E 9.78%, #283577 63.96%)",
                boxShadow: "4px 4px 32px rgba(35, 50, 95, 0.22)",
                borderRadius: 4,
                overflow: "hidden",
              }}
            >
              {/* <video
                style={{ width: "100%", position: "absolute", top: 0 }}
                poster="/media/Video-placeholder-ocr.jpg"
                autoPlay
                controls
              >
                <source
                  src="/media/Nanonets Animation v1.0.mp4"
                  type="video/mp4"
                />
              </video> */}

              <div
                style={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                }}
              >
                <iframe
                  src="https://player.vimeo.com/video/455803970"
                  width="100%"
                  height="100%"
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
            <div style={{ padding: "60px 0" }}>
              <Features features={features.cards} iconHeight={36} />
            </div>
          </div>
        </Container>
      </section>

      <section>
        <Container>
          <div style={{ margin: "50px auto" }}>
            {section3.map((item, index) => (
              <div key={index} className={classes.c_item}>
                <div>
                  <div className={classes.c_title1}>{item.title1}</div>
                  <div className={classes.c_title2}>{item.title2}</div>
                </div>
                <img
                  data-src={item.media}
                  className={`lazyload ${classes.c_media}`}
                  alt=""
                />
                <div>
                  <div className={classes.c_summary}>{item.summary}</div>
                  <a
                    href={item.ctaLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={classes.c_link}
                  >
                    <span>{item.ctaText}</span>
                    <ArrowForwardIcon
                      style={{ fontSize: 14, marginLeft: 10 }}
                    />
                  </a>
                </div>
              </div>
            ))}
          </div>
        </Container>
      </section>

      <section
        style={{
          background:
            "linear-gradient(103.64deg, #48559E 9.78%, #283577 63.96%)",
          padding: "100px 0",
        }}
      >
        <Container>
          <div className={classes.d_box}>
            <div className={classes.d_title1}>{getStarted.title1}</div>

            <div className={classes.d_title2}>{getStarted.title2}</div>

            <div className={classes.d_cards}>
              {getStarted.cards.map((item, index) =>
                index === 0 ? (
                  <div
                    key={index}
                    role="link"
                    tabIndex="0"
                    className={classes.d_card}
                    onClick={() => {
                      window.open(item.link, "_blank");
                    }}
                    style={{
                      background: colors[index],
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <div style={{ textAlign: "center" }}>
                      <img
                        style={{ height: 24 }}
                        data-src={item.image}
                        className="lazyload"
                        alt=""
                      />
                      <div
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          color: "#fff",
                          marginTop: 6,
                        }}
                      >
                        {item.name}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div
                    key={index}
                    role="link"
                    tabIndex="0"
                    className={classes.d_card}
                    onClick={() => {
                      window.open(item.link, "_blank");
                    }}
                    style={{ background: colors[index] }}
                  >
                    <div className={classes.d_image_box}>
                      <img
                        className={`lazyload ${classes.d_image}`}
                        data-src={item.image}
                        alt=""
                      />
                    </div>
                    <div className={classes.d_footer}>
                      <span className={classes.d_name}>{item.name}</span>
                      <div
                        style={{
                          color: "rgba(255, 255, 255, 0.5)",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <ImportContactsIcon
                          style={{ fontSize: 12, marginRight: 5 }}
                        />
                        <span style={{ fontSize: 10 }}>
                          {" "}
                          {item.processedDocumentCount}
                        </span>
                      </div>
                      <span className={classes.d_footer_cta}>
                        <span>{item.ctaText}</span>
                        <ArrowForwardIcon
                          style={{ fontSize: 12, marginLeft: 10 }}
                        />
                      </span>
                    </div>
                  </div>
                )
              )}
            </div>

            <div className={classes.d_btns}>
              <button
                className={_classes.button}
                style={{ width: "auto" }}
                onClick={scheduleDiscoveryCall}
              >
                Request a demo
              </button>
              <button
                className={clsx(_classes.button, _classes.button_outlined)}
                style={{
                  color: "#fff",
                  border: "1px solid #fff",
                  width: "auto",
                }}
                onClick={() => {
                  window.open(getStarted.ctaLink2, "_blank");
                }}
              >
                {getStarted.ctaText2}
              </button>
            </div>
          </div>
        </Container>
      </section>

      <section
        className={classes.bg2}
        style={{ paddingTop: 100, paddingBottom: 200 }}
      >
        <Container style={{ position: "relative" }}>
          <div style={{ margin: "auto" }}>
            <div className={classes.g_heading}>You’re in good company!</div>

            <div style={{ marginTop: 72 }}>
              <Customers />
            </div>

            <div style={{ paddingTop: 80 }}>
              <Grid container spacing={6}>
                {caseStudies.cards.map((item, index) => (
                  <Grid item key={index} xs={12} sm={6} md={4}>
                    <div
                      className={classes.e_box}
                      onClick={() => {
                        window.open(item.link, "_blank");
                      }}
                    >
                      <div className={classes.e_box_inner}>
                        <div className={classes.e_title1}>{item.title1}</div>
                        <div className={classes.e_title2}>{item.title2}</div>
                        <div className={classes.e_body}>{item.body}</div>
                        <span className={classes.e_cta}>
                          <span>{item.ctaText}</span>
                          <ArrowForwardIcon
                            style={{ fontSize: 12, marginLeft: 10 }}
                          />
                        </span>
                      </div>
                    </div>
                  </Grid>
                ))}
              </Grid>
            </div>

            <div>
              <div className={classes.f_points}>
                {featurePoints.map((item) => (
                  <div key={item.name} className={classes.f_point}>
                    <CheckCircleIcon className={classes.f_check} />
                    <span className={classes.f_name}>{item.name}</span>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Container>
      </section>

      <ContactForm />
    </Layout>
  );
};

export default OCR;

export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "home" } }) {
      frontmatter {
        seoTitle
        seoDescription
        section1 {
          heading
          heading2
          ctaCaption
          image
          cta1 {
            text
            link
          }
        }
        features {
          cards {
            title
            summary
            icon
          }
        }
        section3 {
          title1
          title2
          summary
          media
          ctaText
          ctaLink
        }
        getStarted {
          title1
          title2
          ctaText2
          ctaLink2
          cards {
            name
            image
            link
            processedDocumentCount
            ctaText
          }
        }
        caseStudies {
          cards {
            title1
            title2
            body
            ctaText
            link
          }
        }
        featurePoints {
          name
        }
        customers {
          title
          logos {
            url
          }
        }
      }
    }
  }
`;
